import React, { useEffect, useState } from 'react'
import { AbortController } from 'node-abort-controller'
import makeRequest from '../../helpers/make-request'
import NewsDetailView from './news-view'
import { useTranslation } from 'react-i18next'

const NewsDetailCotroller = ({ slug }) => {
  const [loading, setLoading] = useState(true)
  const [dataNewsDetail, setDataNewsDetail] = useState([])
  const controller = new AbortController()
  const { i18n } = useTranslation();
  // catch with useEffect so the data will be contained
  const getDataNewsDetail = async () => {
    setLoading(true)
    const fields = 'id,title,slug,acf,human_date,thumbnail,related,location,genre,featured_media'
    const headers = {
      'Content-Type': 'application/json',
    }

    // fetch the data with makerequest
    makeRequest({
      headers,
      endPoint: 'posts',
      signal: controller.signal,
      params: {
        slug,
        fields,
        lang: i18n.language
      },
    }).then((resp) => {
      setDataNewsDetail(resp.data[0])
      setLoading(false)
    })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }
  useEffect(() => {
    getDataNewsDetail()
  }, [])

  useEffect(() => {
    getDataNewsDetail()
    return () => {
      controller.abort()
    }
  }, [slug])

  const viewProps = {
    data: dataNewsDetail,
    loading,
  }
  return (
    <NewsDetailView {...viewProps}/>
  )
}

export default NewsDetailCotroller
